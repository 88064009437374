function ServerCard({ name, map, ip, port, image }) {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 flex flex-col justify-between">
      <div className={"flex flex-row justify-between"}>
        <div className={"flex flex-col"}>
          <h3 className="text-xl font-bold mb-2">{name}</h3>
          <p>
            <strong>Mapa:</strong> {map}
          </p>
          <p>
            <strong>IP:</strong> {ip}
          </p>
          <p>
            <strong>Port:</strong> {port}
          </p>
        </div>
        <img
          className={"border-2 border-black w-28 h-28"}
          alt={"queijo"}
          src={image}
        ></img>
      </div>
      <button
        onClick={() => (window.location.href = `steam://connect/${ip}:${port}`)}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded w-full hover:bg-blue-600"
      >
        Jogar
      </button>
    </div>
  );
}

export default ServerCard;
