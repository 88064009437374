import React from "react";
import { Routes, Route } from "react-router-dom";
import Connect from "./Connect";
import ServerCard from "./components/ServerCard";

function App() {
  return (
    <div className="bg-gray-200 text-gray-900 min-h-screen">
      <Routes>
        <Route path="/server/connect/:serverIpAndPort" element={<Connect />} />
      </Routes>
      <div className="relative bg-gray-800 bg-center bg-cover h-[150px] sm:h-[200px] md:h-[250x] lg:h-[300px]">
        <div className="absolute inset-0 flex flex-col items-center gap-0 justify-center bg-black bg-opacity-40 text-white">
          <img
            className={"h-[100px] sm:h-[150px] md:h-[200x] lg:h-[250px]"}
            alt={"banner"}
            src={"./banner.png"}
          />
          <h1 className={"text-xs sm:text-sm md:text-sm lg:text-xl"}>
            O melhor servidor brasileiro de Unturned!
          </h1>
        </div>
      </div>
      <div className="container mx-auto p-6">
        <h2 className="text-2xl font-semibold mb-4">Servidores Disponíveis</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <ServerCard
            name="[BR] Toca dos Ratos"
            map="Russia"
            ip="104.234.224.222"
            port="29065"
            image="./queijo_azul.png"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
