import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function Connect() {
  const { serverIpAndPort } = useParams();

  useEffect(() => {
    if (serverIpAndPort) {
      const steamUrl = `steam://connect/${serverIpAndPort}`;
      window.location.href = steamUrl;
    }
  }, [serverIpAndPort]);

  return (
    <div>
      <p>Redirecionando para o servidor...</p>
    </div>
  );
}

export default Connect;
